/*
* @Author: 曹俊杰
* @Date: 2023-01-10 18:00:36
* @Module: 欢迎页
*/
<template>
  <div class="box">
    <video src="https://meimengapp.oss-cn-beijing.aliyuncs.com/video/index.mp4" autoplay="autoplay" loop="loop"
      muted="muted"></video>
    <div class="content">
      <div class="top_btn" @click="juhe">
        聚合办公
      </div>
      <div class="header">
        <!-- <div class="logo"></div> -->
      </div>
      <div class="main">
        <div class="title"></div>
        <div class="ext">多元开放·高效共赢</div>
        <div class="btns">

          <div class="btn" @click="onlink('https://zhq.mcn-open.com')">
            真好签
          </div>

          <div class="btn btnStyle" @click="onlink('https://www.znsign.com')">
            中能E签 
          </div>
        </div>
      </div>
      <div class="footer">
        <a style="margin-right:10px;" href="https://beian.miit.gov.cn">豫ICP备19039737号-1</a>

        <a href="https://beian.miit.gov.cn">豫ICP备19039737号-6</a>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() { },
  methods: {
    onlink(href) {
      window.open(href, "_blank");
    },
    juhe() {

      this.$prompt('请输入访问密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {

        if (value == '1014') this.$router.push({ path: '/menu' })
        else this.$message({
          type: 'error',
          message: '输入的密码错误'
        });

      })
    }
  },
};
</script>
<style lang='scss' scoped>
.box {
  .top_btn{
    position: absolute;
    top: 50px;
    right: 50px;
    color: #ffffff;
    cursor: pointer;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }

  .content {
    width: 1200px;
    height: 100vh;
    z-index: 10;
    margin: 0 auto;

    .header {
      display: flex;
      align-items: center;
      height: 80px;

      .logo {
        width: 135px;
        height: 32px;
        background-image: url(~@/static/wellcome/logo.png);
        background-size: 135px 32px;
      }
    }

    .main {
      width: 710px;
      height: 322px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      @keyframes tit {
        0% {
          transform: scale(0.8);
        }

        100% {
          transform: scale(1);
        }
      }

      .title {
        background-image: url(~@/static/wellcome/title.png);
        width: 710px;
        height: 126px;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        animation: tit 5s;
      }

      .ext {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 56px;
        /* identical to box height, or 233% */

        text-align: center;
        letter-spacing: 0.5em;

        color: #ffffff;
        margin-top: 40px;
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        .btn {
          width: 128px;
          height: 50px;
          background: rgba(255, 255, 255, 0.01);
          border: 1.5px solid rgba(255, 255, 255, 0.6);
          backdrop-filter: blur(15px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 4px;
          text-align: center;

          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 50px;
          color: #ffffff;
          cursor: pointer;
          margin-right: 40px;

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

.footer {
  a {
    color: #ccc !important;
  }

  position: absolute;

  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
}
.btnStyle{
  position: relative;
  span{
    position: absolute;
    right: 6px;
    top: -16px;
  }
}
</style>